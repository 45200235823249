.description {
    
    border: 1px solid #1976D2;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 1rem;    
}

.alert {margin-bottom: 0.5rem;}