.templateMainContainer {    
    background-color: #ffffff;    
    font-size: 200px;    
    line-height: 1.2;
    ;
}

.bgrImage {
    position: absolute;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    
}  


