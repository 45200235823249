.previewContainer {    
    width: 100%;  
    height: 480px;
    margin-top: 10px;
}
.scaledPreview {
    transform-origin: left top;
    scale: 0.25;
}

.renderArea {outline: 5px dotted grey; }
