@font-face {
  font-family: "Evolventa";
  src: url('../fonts/evolventa.ttf') format('truetype');
}

@font-face {
  font-family: "Furius";
  src: url('../fonts/Furius-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Podkova";
  src: url('../fonts/podkova.ttf') format('truetype');
}

@font-face {
  font-family: "Playfair";
  src: url('../fonts/playfair.ttf') format('truetype');
}

@font-face {
  font-family: "Caveat";
  src: url('../fonts/сaveat.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
